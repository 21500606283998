$solidWhite:    #FFFFFF;
$doveGrey:      #707070;
$shadow:        #00000029;
$scorpion:      #5F5F5F;
$mercury:       #E8E8E8;
$manatee:       #8E8E90;
$froly:         #F48989;
$alto:          #DEDEDE;
$fern:          #5CBC79;
$mojo:          #101D2A;
$casablanca:    #F9B657;
$navajo-white:  #B4C9E0;

$whatsapp:      #59ce72;
$whatsapp-hover:#101d2a;
