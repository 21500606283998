@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?5upqj9');
  src:  url('fonts/icomoon.eot?5upqj9#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?5upqj9') format('truetype'),
    url('fonts/icomoon.woff?5upqj9') format('woff'),
    url('fonts/icomoon.svg?5upqj9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-rotate-left:before {
  content: "\e923";
}
.icon-rotate-right:before {
  content: "\e924";
}
.icon-crop:before {
  content: "\e922";
}
.icon-check-icon:before {
  content: "\e921";
}
.icon-import-csv-icon:before {
  content: "\e920";
}
.icon-icon-trash-can:before {
  content: "\e91f";
}
.icon-iconmonstr-gift:before {
  content: "\e91e";
}
.icon-paper-clip:before {
  content: "\e91c";
}
.icon-male:before {
  content: "\e91a";
}
.icon-female:before {
  content: "\e91b";
}
.icon-intraoral_snoring_device:before {
  content: "\e919";
}
.icon-cpap:before {
  content: "\e918";
}
.icon-pill:before {
  content: "\e917";
}
.icon-Icon-awesome-syringe:before {
  content: "\e914";
}
.icon-Icon-awesome-cocktail:before {
  content: "\e915";
}
.icon-Grupo-409:before {
  content: "\e916";
}
.icon-oximetro:before {
  content: "\e913";
}
.icon-download:before {
  content: "\e910";
}
.icon-Icon-awesome-caret-down:before {
  content: "\e900";
}
.icon-Icon-awesome-whatsapp:before {
  content: "\e901";
}
.icon-Icon-ionic-ios-add:before {
  content: "\e902";
}
.icon-Icon-ionic-ios-arrow-down:before {
  content: "\e903";
}
.icon-Icon-ionic-ios-calendar:before {
  content: "\e904";
}
.icon-Icon-ionic-ios-camera:before {
  content: "\e905";
}
.icon-Icon-ionic-ios-close:before {
  content: "\e906";
}
.icon-Icon-ionic-ios-menu:before {
  content: "\e907";
}
.icon-Icon-ionic-ios-search:before {
  content: "\e908";
}
.icon-Icon-material-edit:before {
  content: "\e909";
}
.icon-Icon-material-exit-to-app:before {
  content: "\e90a";
}
.icon-Icon-metro-profile:before {
  content: "\e90b";
}
.icon-spinner:before {
  content: "\e90c";
}
.icon-Icon-open-person:before {
  content: "\e90d";
}
.icon-medical:before {
  content: "\e90e";
}
.icon-document:before {
  content: "\e90f";
}
.icon-bug-solid:before {
  content: "\e911";
}
.icon-share:before {
  content: "\e91d";
}
.icon-credit:before {
  content: "\e912";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-asterisk:before {
  content: "\eae9";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-checkbox-checked:before {
  content: "\ea52";
}
.icon-checkbox-unchecked:before {
  content: "\ea53";
}
.icon-play:before {
  content: "\ea1c";
}
.icon-pause:before {
  content: "\ea1d";
}
