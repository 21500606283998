@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import "./assets/variables.scss";
@import "./assets/_forms";
@import "bootstrap/dist/css/bootstrap.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "./assets/icomoon/style.css";
@import 'ngx-toastr/toastr';

:root {
  font-size: 62.5%;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin: 0;
  background-color: $mercury;
}

.row {
  margin: 0px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: $shadow;
}

::-webkit-scrollbar-thumb:hover {
  background: $scorpion;
}

.modal-backdrop {
  z-index: 1999 !important;
}

.modal {
  z-index: 2000 !important;
}

.white-space-nowrap {
  white-space: nowrap;
}

.toast-container .ngx-toastr {
  box-shadow: 0px 3px 6px $shadow;
  font-size: 16px;
  width: 100%;
  opacity: 0.9 !important;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0px 3px 6px $shadow;
}

.toast-close-button {
  margin: 5px;
}

.toast-title {
  font-size: 18px;
}

.toast-error {
  background-color: #00000099;
}

.toast-success {
  background-color: $fern;
}

.toast-info {
  background-color: $mojo;
}

.toast-warning {
  background-color: $casablanca;
}

.toast-progress {
  background-color: $manatee;
}


.indicator-reverse {
  display: inline-block;
  transform: rotate(180deg);
}
