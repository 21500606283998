@import "./variables.scss";

.card:not(:first-of-type) {
  margin: 15px 0;
}

.card {
  background-color: $solidWhite;
  border-radius: 16px;
  box-shadow: 0 3px 6px $shadow;
  padding: 30px 20px;

  h3 {
    font-weight: 500;
    font-size: 20px;
    color: $scorpion;
    margin-bottom: 10px;
  }
}

.full-loading {
  align-items: center;
  padding-bottom: 25px;

  .spinner-border {
    color: $scorpion;
    font-size: 18px;
  }
}

.backdropLoading {
  background-color: $shadow;
  z-index: 99999;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner-border {
    color: $scorpion;
  }
}

form {
  width: 100%;
}

.separator {
  &:not(:first-of-type) {
    margin-top: 15px;
  }
}

.titlebar {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  .select-all {
    margin-left: 20px;
    display: flex;
    align-items: center;

    label {
      font-size: 15px;
      margin-left: 5px;
      margin-bottom: 0;
    }
  }
}

.input {
  margin: 5px 0;
}

.remove-padding {
  padding: 0;
}

.radio-option {
  display: block;

  input {
    margin-right: 6px;
    margin-bottom: 2px;
    width: 14px;
    height: 14px;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
    &:disabled {
      cursor: default !important;
    }
  }

  label {
    vertical-align: middle;
    font-weight: unset;
    font-size: 15px;
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }
}

label.radio-label__disabled:hover {
  cursor: default !important;
}

.checkbox-field {
  &__title {
    color: $scorpion;
    display: block;
    margin-bottom: 8px;
    font-size: 15px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-weight: 500;
  }
}

.checkbox-option {
  display: flex;
  align-items: center;

  input {
    margin-right: 6px;
    margin-bottom: 2px;
    width: 14px;
    height: 14px;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
    &:disabled {
      cursor: default !important;
    }
  }

  label {
    vertical-align: middle;
    font-weight: unset;
    font-size: 15px;
    display: flex;
    margin: 0;
    &:hover {
      cursor: pointer;
    }
    &:disabled {
      cursor: default !important;
    }
  }
}

textarea {
  resize: none;
  padding: 10px;
  width: 100%;
  border: 1px solid $scorpion;
  border-radius: 8px;
  font-size: 14px;
  &:focus {
    outline: none;
  }
}

//Lista de selecionados

.list {
  max-width: fit-content;
  p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    color: $scorpion;
    button {
      margin-left: 15px;
    }
  }
}

//buttons

.add-button {
  border: none;
  background-color: transparent;
  text-align: start;
  max-width: 175px;
  margin-top: 10px;
  color: $mojo;
  font-size: 16px;
}

.remove-button {
  font-size: 16px;
  color: $froly;
  background-color: transparent;
  border: none;
  height: 40px;
}

.buttons {
  width: 100%;
}

.btn {
  border-radius: 0.6rem;
  font-size: 1.5rem;
  font-weight: 500;
  width: 100%;
  padding: 5px;

  &:focus {
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
}

.btn-primary {
  background-color: $mojo !important;
  border-color: $mojo !important;

  &:active,
  &:focus,
  &:hover {
    border-color: $mojo !important;
    background-color: $mojo !important;
  }
}

.btn-outline-primary {
  border-color: $mojo;
  color: $mojo;

  &:active,
  &:focus,
  &:hover {
    border-color: currentColor !important;
    background-color: $mojo !important;
    color: white !important;
  }
}

.btn-secondary {
  background-color: $froly;
  border-color: $froly;

  &:active,
  &:focus,
  &:hover {
    border-color: $froly !important;
    background-color: $froly !important;
  }
}

.btn-tertiary {
  color: $solidWhite;
  background-color: $froly;
  border-color: $froly;

  &:active,
  &:focus,
  &:hover {
    color: $solidWhite !important;
    border-color: $froly !important;
    background-color: $froly !important;
  }
}

.btn-link {
  text-decoration: none;
  font-weight: 400;
  color: $froly;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
    color: $froly;
  }
}

// Field inputs

.field {
  display: block;
  width: 100%;

  padding: 0;

  &.horizontal {
    display: flex;

    label {
      line-height: 3;
    }

    input {
      width: auto;
      margin-left: 1rem;
    }
  }

  &__title {
    display: block;
    margin-bottom: 8px;
    font-size: 15px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $scorpion;

    &--left {
      display: inline-block;
    }
  }

  input {
    padding: 10px;
    width: 100%;
    border: 1px solid $scorpion;
    border-radius: 8px;
    height: 48px;
    font-size: 14px;

    &:focus {
      outline: none;
    }

    &.error {
      border: 2px solid $froly;
    }
  }

  input.background-transparent {
    background-color: transparent;
    border-color: white;
  }
}

.remove-vertical-space {
  padding: 0px;
}

.capitalize {
  text-transform: uppercase;
}
